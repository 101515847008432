import React from 'react';
import './ResultDisplay.css';


function ResultDisplay({ coverage, tenure_value, premium, onBack, gst, exclusiveGST }) {
  return (
    <div className="result-wrapper">
      <div className="result-container">
        {/* <img src={logo} alt="Logo" className="result-logo"/> */}
        <h1 className="result-title">Loan Protection</h1>
        <div className="result-group">
          <label>Coverage:</label>
          <span className="result-value">₹{coverage.toLocaleString()}</span>
        </div>
        <div className="result-group">
          <label>Tenure:</label>
          <span className="result-value">{tenure_value}</span>
        </div>
        <div className="result-group-premium">
          <label>Flashaid Price:</label>
          <span className="result-value">₹{exclusiveGST}</span>
        </div>
        <div className="result-group-premium">
          <label>18% GST:</label>
          <span className="result-value">₹{gst}</span>
        </div>
        
        <div className="result-group-premium">
          <label>Total Premium:</label>
          <span className="result-value">₹{premium}</span>
        </div>
        {/* <div className="result-group">
          <span className="result-gst">Inc. of 18% GST</span>
        </div> */}
        <button onClick={onBack} className="result-button">Back</button>
      </div>
      <div className="additional-text">
        <p>
          The premium of ₹{premium.toLocaleString()} will be deducted from the loan amount towards the loan protection policy selected as per the client.
          <br />
          *T&C applied
        </p>
      </div>
    </div>
  );
}

export default ResultDisplay;
