import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import InputForm from './InputForm.js';
import ResultDisplay from './ResultDisplay';
import Header from './Header.js';
import Section from './Section.js';
import TirumalaDC from './TirumalaDC/TirumalaDC.js';
import Login from './Login/Login'; // Import the Login component

function App() {
  const [result, setResult] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem('isAuthenticated') === 'true'
  );

  const handleSubmit = (data) => {
    setResult(data);
  };

  const handleBack = () => {
    setResult(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('isAuthenticated');
    setIsAuthenticated(false);
  };

  useEffect(() => {
    setIsAuthenticated(sessionStorage.getItem('isAuthenticated') === 'true');
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <div>
            <Header />
            {result ? (
              <ResultDisplay {...result} onBack={handleBack} />
            ) : (
              <InputForm onSubmit={handleSubmit} />
            )}
            <Section />
          </div>
        }/>

        <Route path="/login" element={
          <Login setAuthenticated={setIsAuthenticated} />
        }/>

        <Route path="/generate-lead" element={
          isAuthenticated ? (
            <TirumalaDC />
          ) : (
            <Login setAuthenticated={setIsAuthenticated} />
          )
        }/>

        <Route path="/logout" element={
          <div>
            <button onClick={handleLogout}>Logout</button>
          </div>
        }/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
