import React from 'react';
import './Section.css';
import howToImage from './emoji.png'; 
import faqImage from './question.png';
import instagram from './insta-icon.png'; 
import linkedin from './linkedin-icon.png'; 



const Section = () => {
  return (
    <div className="App">
      
      <section id="how-to-use" className="section">
        <div className="content">
          <img src={howToImage} alt="How to use" className="section-image" />
          <div className="text">
            <h2>How to use?</h2>
            <h3>Now calculate the Premium in just 2 steps</h3>
            <p>
              <strong>Step 1:</strong> To use this calculator simply input the value of the loan amount desired by the client.
            </p>
            <p>
              <strong>Step 2:</strong> Choose the tenure from the drop down for which the loan is desired for and click Submit.
            </p>
            <p>Please note that the coverage amount will be fetched based on the nearest value of the loan amount.</p>
            <p>For eg. If the value entered is 3,70,000 then the nearest upper coverage will be 4,00,000.</p>
          </div>
        </div>
      </section>
      <section id="faq" className="section">
        <div className="content">
          <img src={faqImage} alt="FAQ" className="section-image" />
          <div className="text">
            <h2>FAQ</h2>
            <p><strong>Q:</strong> Are the prices shown inclusive of GST?<br /><strong>A:</strong> Yes, the final prices are inclusive of 18% GST.</p>
            <p><strong>Q:</strong> Does this calculator store your data?<br /><strong>A:</strong> No, this calculator is completely safe to use.</p>
            <p><strong>Q:</strong> Is there any limit to number of times the calculator can be used?<br /><strong>A:</strong> No, you can use this calculator freely.</p>
          </div>
        </div>
      </section>
      <section id="contact" className="section contact-section">
        <div className="content">
          <img src="https://flashaid.in/assets/images/icons/call.svg" alt="Contact Us" className="section-image" />
          <div className="text contact-text">
            <h2>Contact Us</h2>
            <p style={{ fontSize: '1.5em', fontWeight: 'bold', width: '100%', textAlign: 'center' }}>
              For any  queries contact our support team.
            </p>
            {/* <p style={{ fontSize: '0.8em', color: '#FF00FF', textAlign: 'center' }}>
              Schedule a call with Flashaid
            </p> */}
            <button className="contact-number">8045 8888 38</button>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Or email us at <a href="mailto:info@flashaid.in">info@flashaid.in</a></p>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <div className="footer-column">
          <ul>
            <li><a href="https://ttmscredit.co.in/faq.html">FAQ</a></li>
            <li><a href="https://ttmscredit.co.in/society_br.html">Branch Locator</a></li>
            <li><a href="https://flashaid.in/">Explore Other services by flashaid</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <ul>
            <li><a href="https://ttmscredit.co.in/fd_calc.html">FD Calculator</a></li>
            <li><a href="https://ttmscredit.co.in/fd_calc.html">RD Calculator</a></li>
            <li><a href="https://ttmscredit.co.in/fd_calc.html">Daily Deposit Calculator</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Follow us on</h3>
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/flashaid/mycompany/"><img src={linkedin} alt="linkedin" className="linkedin-image" /></a>
            <a href="https://www.instagram.com/flashaid_/"><img src={instagram} alt="linkedin" className="whatsapp-image" /></a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Section;
