import React from 'react';
import './SuccessModal.css';

const SuccessModal = ({ message, onClose, popupResponse }) => {
  console.log(popupResponse,"from modal");
  
  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-container success" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <span className="close-btn" onClick={onClose}>&#10005;</span>
        </div>
        <div className="modal-body">
          <span>{message}</span>
          {popupResponse && (
            <div className="popup-response">
              <p><strong>FAID:</strong> {popupResponse.faid}</p>
              <p><strong>Status:</strong> {popupResponse.status}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;

