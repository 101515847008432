export const branchList = [
  "AIROLI",
  "ASHTI",
  "AHMADNAGAR",
  "AHMEDABAD",
  "(Aurangabad) SAMBHAJINAGAR",
  "AKOLA",
  "AKOLE",
  "ALEPHATA",
  "ALDONA",
  "AMALNER",
  "AMRAVATI",
  "AMRELI",
  "ANKOLA",
  "ANDHERI",
  "ASHTA HARINARAYAN",
  "ATPADI",
  "ARNI",
  "CHANDGAD",
  "AUSA",
  "ARAMBOL",
  "BANDA",
  "BARAMATI",
  "BHADRAWATI",
  "BALLARPUR",
  "BADLAPUR",
  "BELAGAVI",
  "BEDKIHAL",
  "BHANDUP",
  "BHIWANDI",
  "BORIVALI",
  "BALKUM",
  "BRAMHAPURI",
  "BICHOLIM",
  "BYCULLA",
  "CALANGUTE",
  "CHANDGAD",
  "CHARKOP",
  "CHANDRAPUR",
  "CHIMUR",
  "CHAKAN",
  "CHARMOSHI",
  "CHIPLUN",
  "CUNCOLIM",
  "CURCHOREM",
  "CANACONA",
  "DADAR",
  "DANDELI",
  "DAUND",
  "DIGHI",
  "DELIDELI",
  "DEVGAD",
  "DEVRUKH",
  "DIVA",
  "DOMBIVALI",
  "GADAG",
  "GADHINGLAJ",
  "GAVKHADI",
  "GHATKOPAR",
  "GHOEGAON",
  "Gandhinagar",
  "GONDAL",
  "HALVAD",
  "HALGA",
  "HINGNA",
  "HINGANGHAT",
  "ILKAL",
  "ISLAMPUR",
  "JAWALA BAZAR",
  "JUNNAR",
  "JAMNAGAR",
  "JOGESHWARI",
  "KALYAN",
  "KAMPTEE",
  "KAMOTHE",
  "KHARGHAR",
  "KALAMBOLI",
  "KHAR BRANCH",
  "KOLHAPUR",
  "KUDAL",
  "KUMTA",
  "KURLA",
  "KARAD",
  "KANKAVLI",
  "KARWAR",
  "KADEGAON",
  "KURDUWADI",
  "KOREGAON",
  "KORPANA",
  "KODOLI",
  "KOPARKHAIRANE",
  "LANJA",
  "LOTE",
  "LOWER PAREL",
  "LONAVALA",
  "MADH",
  "MAHAD",
  "MAPUSA",
  "MANISH NAGAR",
  "MALVAN",
  "MANCHAR",
  "MANGALORE",
  "MANKHURD",
  "MANDVI",
  "MARGOA",
  "MALAD",
  "MALLAPUR",
  "MARDOL",
  "MORBI",
  "MULUND",
  "MURUD",
  "MURBAD",
  "MULCHERA",
  "MIRA ROAD",
  "NIKOL",
  "NASHIK",
  "NAGPUR",
  "NANDED",
  "NARAYANGAON",
  "NALLASOPARA",
  "NERUL",
  "OTUR",
  "PARATWADA",
  "PANJIM",
  "PARGAON",
  "PANCHGANI",
  "PHALTAN",
  "PANVEL",
  "PALGHAR",
  "PARNER",
  "PATAS",
  "PAIT",
  "PANDHARPUR",
  "PATODA",
  "PONDA",
  "POINGUINIM",
  "PUSAD",
  "QUEPEM",
  "RAJAPUR",
  "RAJKOT",
  "RANCHHD NAGAR",
  "RATNAGIRI",
  "RAMTEK",
  "RAJGURUNAGAR",
  "RAM MANDIR",
  "RAVET",
  "RAJURA BRANCH (RAJ54)",
  "SANGVI",
  "SAKINAKA",
  "SAIDAPUR",
  "SANGUEM",
  "SANGOLA",
  "SAVADATTI",
  "SAWANTWADI",
  "KADA(SAVARGAON)",
  "SAWARDE",
  "SANGMESHWAR",
  "SANGAMNER",
  "SASWAD",
  "SANGLI",
  "SELOO",
  "SHIRODA",
  "SHRIGONDA",
  "SATARA",
  "SHIRALA",
  "SHIRUR",
  "SION",
  "SURAT",
  "TALEGAON",
  "TALOJA",
  "THANE",
  "TISK USGAON",
  "TARDEO",
  "ULHASNAGAR",
  "UMARKHED",
  "UDUPI",
  "UMBRAJ",
  "VADUJ",
  "VASULI PHATA",
  "VAVE",
  "VASCO",
  "VAIBHAVWADI",
  "VASHI",
  "VERSOVA",
  "VENGURLA",
  "VASAI",
  "VIRAR",
  "WAI",
  "WARDHA",
  "WARORA",
  "WANI",
  "WARUD",
  "WALUJ",
  "WADI",
  "YAVATMAL",
];
