import React from 'react';
import './Header.css';
import logo from './logo.webp';

const Header = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="header">
      <img src={logo} alt="Logo" className="header-logo" />
      <nav className="header-nav">
        <ul>
        
          <li onClick={() => scrollToSection('calculator')}>Calculator</li>
          <li onClick={() => scrollToSection('how-to-use')}>How to Use</li>
          <li onClick={() => scrollToSection('faq')}>FAQ?</li>
          <li onClick={() => scrollToSection('contact')}>Contact Us</li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
