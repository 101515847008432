import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import './Login.css'; // Import the CSS file
import LoginImage from '../logo.webp';
import ClockLoader from "react-spinners/ClockLoader";

const Login = ({ setAuthenticated }) => {
  const override: CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // This centers the element
    display: "block",
    margin: "auto", // Centering in the parent container
    borderColor: "red", // Border color for debugging
  };
  const [contactNumber, setContactNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [sentOtp, setSentOtp] = useState('');
  const[otpLoading,setOTPLoading] = useState(false);
  const navigate = useNavigate();

  const handleSendOtp = async () => {
    const phoneNumberPattern = /^\d{10}$/; 
    if (phoneNumberPattern.test(contactNumber)){
      try {
        setOTPLoading(true);
        console.log(contactNumber);
        const response = await axios.post('https://flashaid-v2-backend-7k5qcren2q-uc.a.run.app/api/v2/user/send-session-otp', {
          phoneNumber: contactNumber,
        });
        setSentOtp(response.data.data.otp);
        sessionStorage.setItem("authOTP",response.data.data.otp);
        setOTPLoading(false);
        setIsOtpSent(true);
      } catch (error) {
        console.error('Error sending OTP:', error);
        setOTPLoading(false);
        alert('Failed to send OTP. Please try again.');
      }
    } else {
      alert('Please enter your 10-digit mobile number');
    }
  };

  const handleLogin = () => {
    const sessionOTP = sessionStorage.getItem("authOTP");
    console.log(sessionOTP,otp,"this is frontend otp")
    if (otp === sentOtp) {
      sessionStorage.setItem('isAuthenticated', 'true');
      setAuthenticated(true);
      navigate('/generate-lead');
    } else {
      alert('Invalid OTP');
    }
  };

  return (
    <>
    {otpLoading? <div className='login-backdrop'>
      <ClockLoader
      className='clip-loader'
      color="white"
      loading={otpLoading}
      cssOverride={override}
      size={70}
      aria-label="Loading Spinner"
      data-testid="loader"
    /> 
    </div>  :  <div className ="login-page">
      
      <img className='login-page-image' src={LoginImage} alt="Login Logo" />
      <div className="login-container">
        <h2 className="login-title">Enter Contact Number</h2>
        <input
          type="text"
          placeholder="Enter your 10 digit mobile number"
          className="login-input"
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
        />
        <button className="login-button" onClick={handleSendOtp}>
          {isOtpSent ? 'Resend OTP' : 'Send OTP'}
        </button>
        {isOtpSent && (
          <>
            <input
              type="text"
              placeholder="Enter OTP"
              className="login-input"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button className="login-button" onClick={handleLogin}>
              Verify OTP
            </button>
          </>
        )}
      </div>
    </div>}
    </>
  );
};

export default Login;
