import React, { useState, useEffect } from 'react';
import './InputForm.css';
import axios from 'axios';

function InputForm({ onSubmit }) {
  const [loanAmounts, setLoanAmounts] = useState(['']);
  const [totalLoanAmount, setTotalLoanAmount] = useState(0);
  const [coverage, setCoverage] = useState('');
  const [tenure, setTenure] = useState('');
  const [pricingData, setPricingData] = useState({});
  const [gst, setGST] = useState('');
  const [exclusiveGST, setExclusiveGST] = useState('');


  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const response = await axios.get('https://script.google.com/macros/s/AKfycbxIEEkqQZgqPp-JLQeEiMXDiPm8GFCO8AZd3-cMYu9jv97RHTat4rljSUiTsDZTGeSV/exec');
        const transformedData = transformPricingData(response.data);
        setPricingData(transformedData);
        if (Object.keys(transformedData).length > 0) {
          setTenure(Object.keys(transformedData)[0]);
        }
      } catch (error) {
        console.error('Error fetching pricing data:', error);
      }
    };

    fetchPricingData();
  }, []);

  const transformPricingData = (data) => {
    const result = {};
    data.forEach(item => {
      const years = item['Insurance coverage (In years)'];
      delete item['Insurance coverage (In years)'];
      result[years] = item;
    });
    return result;
  };

  const handleLoanAmountChange = (index, value) => {
    const updatedLoanAmounts = [...loanAmounts];
    updatedLoanAmounts[index] = value;
    setLoanAmounts(updatedLoanAmounts);
    const totalAmount = updatedLoanAmounts.reduce((sum, amount) => sum + parseFloat(amount || 0), 0);
    setTotalLoanAmount(totalAmount);
  };

  const addLoanAmountField = () => {
    setLoanAmounts([...loanAmounts, '']);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const premium = calculatePremium(totalLoanAmount, tenure);
    const tenure_value = tenure.replace(/_/g, " ");
    onSubmit({ coverage, tenure_value, premium, gst, exclusiveGST });
  };

  const calculatePremium = (loanAmount, tenure) => {
    if (!pricingData[tenure]) {
      return 'N/A';
    }
    
    const amounts = Object.keys(pricingData[tenure]).map(Number).sort((a, b) => a - b);
    let closestAmount = amounts.find(amount => amount >= loanAmount);
    if (!closestAmount) {
      closestAmount = amounts[amounts.length - 1];
    }
    setCoverage(closestAmount);
    const basePremium = pricingData[tenure][closestAmount];
    
    let amountWithoutCommas = basePremium.toString().replace(/,/g, "");
    let amountNumber = parseFloat(amountWithoutCommas);
    const premiumWithTax = (amountNumber + (amountNumber * 0.18)).toFixed(3);
    setGST((amountNumber * 0.18).toFixed(3));
    setExclusiveGST(amountNumber);
    return premiumWithTax;
  };

  useEffect(() => {
    if (totalLoanAmount) {
      calculatePremium(totalLoanAmount, tenure);
    }
  }, [totalLoanAmount, tenure, pricingData]);

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <h1 style={{ fontSize: "25px" }} className="form-title">Flashaid Loan Protection Plan</h1>
      {loanAmounts.map((loanAmount, index) => (
        <div className="input-group" key={index}>
          <label>Loan Amount {index + 1}:</label>
          <input
            type="number"
            value={loanAmount}
            onChange={(e) => handleLoanAmountChange(index, e.target.value)}
            placeholder="Please enter your loan amount here"
          />
        </div>
      ))}
      <button type="button" onClick={addLoanAmountField} className="add-loan-button">
        Add More Loan Amount
      </button>
      <div className="input-group">
        <label>Total Loan Amount:</label>
        <input
          type="number"
          value={totalLoanAmount}
          disabled
        />
      </div>
      <div className="input-group">
        <label>Coverage:</label>
        <input
          type="text"
          value={coverage}
          disabled
        />
      </div>
      <div className="input-group">
        <label>Tenure:</label>
        <select
          value={tenure}
          onChange={(e) => setTenure(e.target.value)}
        >
          {Object.keys(pricingData).map(key => (
            <option key={key} value={key}>{key.replace(/_/g, ' ')}</option>
          ))}
        </select>
      </div>
      <button type="submit" className="submit-button">Submit</button>
    </form>
  );
}

export default InputForm;
